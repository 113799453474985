<template>
  <div class="form-dependencies">
    <template v-if="dependenciesInStore && dependenciesInStore.length">
      <esmp-collapser
        v-for="(dependency, dependencyIndex) in dependenciesInStore"
        :key="dependency.techId"
        class="form-workflow__block form-workflow__block--gray"
        :title="`Условие ${(dependencyIndex + 1)} - ${dependency.bandType}`"
        use-v-if
      >
        <div class="form-workflow__multi-dependency">
          <div class="form-workflow__multi-dependency__cell">
            <esmp-select2
              :model-value="dependency.bandType"
              @on-change="updateDependencyBandType($event, dependencyIndex)"
              placeholder="Выберите тип"
              :options="bandTypeList"
            />
          </div>
          <div class="form-workflow__multi-dependency__cell">
            <esmp-select2
              v-if="dependency.bandType === bandTypes.workflow"
              :model-value="dependency.flowName"
              @on-change="updateDependencyFlowName($event, dependencyIndex)"
              placeholder="Выберите workflow"
              :options="workflows"
              label-prop="name"
              :reduce="(option) => option.name"
              filterable
            />
            <esmp-select2
              v-else-if="dependency.bandType === bandTypes.queue"
              :model-value="dependency.flowName"
              @on-change="updateDependencyFlowName($event, dependencyIndex)"
              placeholder="Выберите очередь"
              :options="queues"
              label-prop="name"
              :reduce="(option) => option.name"
              filterable
            />
          </div>
        </div>
        <div
          v-for="(condition, conditionIndex) in dependency.conditions"
          :key="condition.techId"
          class="form-workflow__condition"
        >
          <div class="form-workflow__multi-dependency">
            <div class="form-workflow__multi-dependency__cell">
              <esmp-select2
                :model-value="condition.controlId"
                @on-change="
                  updateConditionControl($event, dependencyIndex, conditionIndex)
                "
                placeholder="Выберите контрол"
                :options="controls"
                label-prop="text"
                :reduce="(option) => option.value"
                filterable
              />
            </div>
            <div class="form-workflow__multi-dependency__cell">
              <esmp-select2
                :model-value="condition.listValueTechId"
                @on-change="
                  updateConditionListValue(
                    $event,
                    dependencyIndex,
                    conditionIndex,
                  )
                "
                placeholder="Выберите значение"
                filterable
                :options="condition.values"
                label-prop="name"
                :reduce="(option) => option.techId"
              />
            </div>
            <div class="form-workflow__multi-dependency__cell">
              <esmp-button
                icon="trash"
                icon-position="right"
                v-if="conditionIndex > 0"
                @click="removeCondition(dependencyIndex, conditionIndex)"
              />
            </div>
            <div class="form-workflow__multi-dependency__cell">
              <esmp-select2
                :model-value="condition.type"
                @on-change="
                  updateConditionType($event, dependencyIndex, conditionIndex)
                "
                :options="conditionTypesList"
                placeholder="Выберите условие"
                label-prop="text"
                :reduce="(option) => option.value"
              />
            </div>
          </div>
        </div>

        <div class="form-workflow__row form-workflow__row--big-offset">
          <div class="form-workflow__col">
            <esmp-button
              icon="trash"
              icon-position="left"
              @click="removeDependency(dependencyIndex)"
            >
              Удалить зависимость
            </esmp-button>
          </div>
        </div>
      </esmp-collapser>
    </template>
    <h4 v-else class="form-dependencies__empty-text">
      Список зависимостей пуст, добавьте зависимость
    </h4>
    <div class="form-workflow__row form-workflow__row--big-offset">
      <div class="form-workflow__col">
        <esmp-button
          view="outline"
          @click="addDependency"
        >
          Добавить зависимость
        </esmp-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { BAND_TYPES, CONDITION_TYPES, DEFAULT_CONTROL_NAME } from '@/constants/formWorkflow';
import findControlsWithListValues from '@/helpers/findControlsWithListValues';
import getElementById from '@/helpers/getElementById';

export default {
  name: 'FormDependencies',
  props: {
    fields: {
      type: Array,
      default: undefined,
    },
    wfMode: {
      type: String,
      default: undefined,
      required: true,
    },
    workflows: {
      type: Array,
      default: undefined,
    },
    queues: {
      type: Array,
      default: undefined,
    },
    dependencies: {
      type: Array,
      default: undefined,
    },
  },
  data() {
    return {
      conditionTypes: CONDITION_TYPES,
      bandTypes: BAND_TYPES,
    };
  },
  computed: {
    ...mapState({
      // arrow functions can make the code very succinct!
      dependenciesInStore: (state) => state.dependencies.dependencies,
    }),
    deps: {
      get() {
        return this.dependencies;
      },
      set(value) {
        this.$emit('update:dependencies', value);
      },
    },
    controls() {
      // Находим контролы, у которых есть настройка values - только к ним можно привязывать wf/queue
      const controls = findControlsWithListValues(this.fields, []);
      const formattedControls = controls.map((control) => {
        const nameField = control.settings.find((s) => s.techName === 'name');
        return {
          value: control.techId,
          text: nameField.value || DEFAULT_CONTROL_NAME,
        };
      });
      // Если в сторе обновились контролы - необходимо обновить значения в условиях зависмостей
      this.updateDependenciesConditions(formattedControls);
      return formattedControls;
    },
    conditionTypesList() {
      return Object.values(this.conditionTypes);
    },
    bandTypeList() {
      return Object.values(this.bandTypes);
    },
  },
  methods: {
    ...mapActions('dependencies', [
      'initFormDependencies',
      'updateDependencies',
      'addDependencyAction',
      'removeDependencyAction',
      'updateDependencyBandTypeAction',
      'updateDependencyFlowNameAction',
      'updateConditionTypeAction',
      'addConditionAction',
      'removeConditionAction',
      'updateConditionControlAction',
      'updateConditionValuesAction',
      'updateConditionListValueAction',
    ]),
    getNewDependency() {
      return {
        techId: +new Date(),
        bandType: this.bandTypes.queue,
        flowType: Object.keys(this.bandTypes).find(
          (key) => this.bandTypes[key] === this.bandTypes.queue,
        ),
        queue: null,
        workflow: null,
        conditions: [this.getNewCondition()],
      };
    },
    getNewCondition() {
      return {
        techId: +new Date(),
        controlId: null,
        listValueId: null,
        listValueTechId: null,
        type: null,
        values: [],
      };
    },
    addDependency() {
      const newDependency = this.getNewDependency();
      this.addDependencyAction(newDependency);
    },
    removeDependency(dependencyIndex) {
      this.removeDependencyAction(dependencyIndex);
    },
    updateDependencyBandType(bandType, dependencyIndex) {
      const flowType = Object.keys(this.bandTypes).find(
        (key) => this.bandTypes[key] === bandType,
      );
      this.updateDependencyBandTypeAction({
        bandType,
        flowType,
        dependencyIndex,
      });
    },
    updateDependencyFlowName(flowName, dependencyIndex) {
      this.updateDependencyFlowNameAction({ flowName, dependencyIndex });
    },
    updateConditionType(conditionType, dependencyIndex, conditionIndex) {
      this.updateConditionTypeAction({
        conditionType,
        dependencyIndex,
        conditionIndex,
      });
      this.addCondition(dependencyIndex);
    },
    addCondition(dependencyIndex) {
      const newCondition = this.getNewCondition();
      this.addConditionAction({ dependencyIndex, condition: newCondition });
    },
    removeCondition(dependencyIndex, conditionIndex) {
      this.removeConditionAction({ dependencyIndex, conditionIndex });
    },
    updateConditionControl(controlId, dependencyIndex, conditionIndex) {
      const control = getElementById(this.fields, controlId, 'techId');
      this.updateConditionControlAction({
        controlId,
        dependencyIndex,
        conditionIndex,
      });
      if (control) {
        const values = this.getValuesFromControl(control);
        this.updateConditionValuesAction({
          values,
          dependencyIndex,
          conditionIndex,
        });
      } else {
        this.updateConditionValuesAction({
          values: [],
          dependencyIndex,
          conditionIndex,
        });
      }
    },
    updateConditionListValue(listValueTechId, dependencyIndex, conditionIndex) {
      if (listValueTechId) {
        this.updateConditionListValueAction({
          listValueTechId,
          dependencyIndex,
          conditionIndex,
        });
      }
    },
    // Обновление значений в зависимостях. Когда добавился новый контрол или новое значение в контроле
    // или поменяли название -
    // необходимо актуализировать их внутри зависимостей
    /* eslint-disable no-param-reassign */
    updateDependenciesConditions() {
      this.dependencies.forEach((dependency, dependencyIndex) => {
        if (dependency.conditions && dependency.conditions.length) {
          dependency.conditions.forEach((condition, conditionIndex) => {
            if (condition.controlId) {
              const control = getElementById(
                this.fields,
                condition.controlId,
                'techId',
              );
              if (control) {
                const values = this.getValuesFromControl(control);
                this.updateConditionValuesAction({
                  values,
                  dependencyIndex,
                  conditionIndex,
                });
                // Надо проверить есть ли текущее значение
                if (condition.listValueTechId) {
                  // Если есть - проверяем есть ли оно среди значений контрола (мб его уже удалили), если нет -
                  // обнуляем выбранное ранее значение внутри условия
                  const conditionValue = values.find(
                    (value) => value.techId === condition.listValueTechId,
                  );
                  if (!conditionValue) {
                    this.updateConditionListValueAction({
                      listValueTechId: null,
                      dependencyIndex,
                      conditionIndex,
                    });
                  }
                }
              } else {
                // Значит контрол был удалён с формы - обнуляем выбранные ранее значения
                this.updateConditionListValueAction({
                  listValueTechId: null,
                  dependencyIndex,
                  conditionIndex,
                });
                this.updateConditionControlAction({
                  controlId: null,
                  dependencyIndex,
                  conditionIndex,
                });
                this.updateConditionValuesAction({
                  values: [],
                  dependencyIndex,
                  conditionIndex,
                });
              }
            }
          });
        }
      });
    },
    getValuesFromControl(control) {
      const valuesField = control.settings.find((s) => s.techName === 'values');
      return valuesField.values;
    },
  },
  created() {
    this.initFormDependencies(this.dependencies);
  },
  watch: {
    dependenciesInStore: {
      handler(val) {
        this.deps = val;
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/blocks/form-workflow.scss';
.form-dependency {
  margin: 15px 0;
  padding: 15px 0 30px;
  border-bottom: 1px solid;
}
.form-dependencies__empty-text {
  margin-top: -20px;
}
</style>
