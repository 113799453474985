const getElementById = (elements, elementId, idField = 'id') => {
  let resultElement = null;
  if (elements && elements.length) {
    for (let i = 0; i < elements.length; i += 1) {
      const element = elements[i];
      if (element[idField] === elementId) {
        return element;
      }
      if (element.childs && element.childs.length) {
        resultElement = getElementById(element.childs, elementId, idField);
        if (resultElement) {
          return resultElement;
        }
      }
    }
  }
  return resultElement;
};

export default getElementById;
