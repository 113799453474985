const findControlsWithListValues = (elements, controlsWithValues) => {
  const controlsWithValuesTypes = ['select', 'checkboxGroup', 'radioGroup'];
  if (elements && elements.length) {
    elements.forEach((element) => {
      if (controlsWithValuesTypes.indexOf(element.classType) > -1) {
        controlsWithValues.push(element);
      }
      if (element.childs && element.childs.length) {
        findControlsWithListValues(element.childs, controlsWithValues);
      }
    });
  }
  return controlsWithValues;
};

export default findControlsWithListValues;
